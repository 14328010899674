<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
    <div class="fullscreen-bg videoWrapper">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://medeavodka.com/wp-content/uploads/2014/06/MED01_Montage03_02no_congrats_with4th02_WebM_1080p.mp4" type="video/mp4">
        <source src="movie.ogg" type="video/ogg">
        Your browser does not support the video tag.
      </video>
      <div class="overlay-clear">
        <v-container>
          <div class="display-3">
            <span class="thin">Medea</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Vodka</span>
          </div>
        </v-container>
      </div>
    </div>
    <div>
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="display-2 mb-1">WHAT WILL YOUR MEDEA BOTTLE SAY?</div>
            <div>Program your bottle and light up the room.</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">World’s first and only bottle with Bluetooth-capable messaging</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Set sales record as the fastest selling vodka at Costco</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Received global recognition with distribution in 25 countries</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Multi-touch, 2-way, Real time Data Collection</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">First licensee  - establishing proof of concept</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
      <v-container style="display:none;">
        <v-timeline>
          <v-timeline-item
            v-for="n in 4"
            :key="n"
            color="#009bdd"
            large
          >
            <template v-slot:opposite>
              <span>Tus eu perfecto</span>
            </template>
            <v-card class="elevation-2">
              <v-card-title class="headline">Lorem ipsum</v-card-title>
              <v-card-text>
                Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </div>

    <v-img
      src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/Shaq%20thumbs%20up%20with%20Medea.jpg?alt=media&token=eae59646-07a2-4c35-8364-63dfae1755a8">
    </v-img>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Case Studies',
          disabled: true,
          to: ''
        },
        {
          text: 'Medea',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>